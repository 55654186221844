@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');

.font1 {
  font-family: 'Comic Neue', cursive;
}

.cell {
  align-items: center;
  display: flex;
  height: 8.8vh;
  justify-content: center;
  width: 10vw;
  border: 1px solid lightgrey
}

.title-bar {
  display: flex;
  height: 10vh;
  justify-content: center;
  width: 100vw;
  text-align: left;
}