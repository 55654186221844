.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.board-card {
  width: 100%;
  aspect-ratio: 5 / 2;
  font-size: max(2.5vw, 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 4px;
  text-align: center;
}
