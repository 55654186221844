#cultivation table,
#cultivation td {
  text-align: center;
}
#cultivation .dirt {
  background-color: #cc9966; 
  border: 2px solid #774411;
}
#cultivation .deep-water {
  background-color: blue;
  border: 2px solid darkblue;
}
#cultivation .grass {
  background-color: lightgreen;
  border: 2px solid green;
}
#cultivation .sand {
  border: 2px solid orange;
  background-color: lightyellow;
}
#cultivation .trees {
  color: green
}
#cultivation .water {
  border: 1px solid blue;
  background-color: lightskyblue;
}
#cultivation td .piece {
  font-size: 40px;
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 1;
}