.controls-card {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.controls-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.controls-card li {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.game-container {
  width: 100%;
  aspect-ratio: 1;
  max-height: 80vh;
  margin: 0 auto;
}

.hex-grid {
  width: 100%;
  height: 100%;
}

polygon {
  fill: transparent;
  stroke: #333;
  stroke-width: 2;
  transition: fill 0.3s ease;
}

polygon.active {
  fill: rgba(76, 175, 80, 0.3);
}

circle {
  transition: cx 0.2s ease, cy 0.2s ease;
  fill: #2196F3;
  opacity: 0.8;
} 