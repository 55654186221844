body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.active {
  color: purple;
}

a[target='_blank']::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

header .card {
  border: none;
}

.popover {
  max-width: 360px !important;
}

.col-left {
  max-width: 600px !important;
}
.col-main {
  max-width: 600px !important;
}

.notifications {
  min-width: 300px;
}

ul#rooms {
  list-style-type: none;
  padding-left: 0;
}

.user-modality.list-group .list-group-item {
  color: rgb(73, 80, 87);
}
