.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  inset: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #f0f2f5;
  z-index: 1000;
} 