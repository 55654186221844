#tictactoe table,
#tictactoe td {
  border: 1px grey solid;
}

#tictactoe td {
  width: 48px;
  height: 48px;
}

#tictactoe td.most-recent {
  background: rgba(0, 191, 255, 0.5);
}
#tictactoe td.win {
  background: rgba(255, 0, 255, 0.8);
}
