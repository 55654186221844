.fullscreen {
  --fullscreen-z-index: 1000;
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--fullscreen-z-index);
}

textarea.fullscreen {
  --textarea-fullscreen-border-color: #fff;
  resize: none;
  border: 3em solid var(--textarea-fullscreen-border-color);
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.6);
}
/* override bootstrap .form-control:focus */
textarea.fullscreen:focus {
  border-color: var(--textarea-fullscreen-border-color);
  box-shadow: none;
}
textarea.fullscreen.darkmode {
  --darkmode-background: rgb(24, 24, 24);
  background: var(--darkmode-background);
  border: 3em solid var(--darkmode-background);
  color: rgba(255, 255, 255, 0.6);
}

.fullscreen-buttons-container {
  /* TODO should be able to use calc for this to be based on --fullscreen-z-index */
  --fullscreen-items-z-index: 1001;
  position: fixed;
  right: 1em;
  top: 1em;
  z-index: var(--fullscreen-items-z-index);
  display: flex;
  flex-direction: column;
}
.fullscreen-buttons-container .btn {
  margin-bottom: 1em;
}
