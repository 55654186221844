.badge-group {
  display: inline-block;
}

.badge-group .badge:last-child {
  margin-right: 0.25em;
}

.badge-group .badge:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.badge-group .badge:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
