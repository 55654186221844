.ilu2-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  inset: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #f0f2f5;  /* Matching body background from src/index.css */
  z-index: 1000;  /* Using same z-index approach as FullScreen.css */
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  scroll-behavior: smooth;
}

.message {
  max-width: 70%;
  padding: 0.8rem 1rem;
  border-radius: 1rem;
  word-wrap: break-word;
}

.message.user {
  align-self: flex-end;
  background: #007AFF;
  color: white;
  border-bottom-right-radius: 0.3rem;
}

.message.bot {
  align-self: flex-start;
  background: #E9ECEF;
  color: black;
  border-bottom-left-radius: 0.3rem;
}

.input-container {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: white;
  border-top: 1px solid #E9ECEF;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1001;  /* Above container */
}

.input-container textarea {
  flex: 1;
  padding: 0.8rem;
  border: 1px solid #E9ECEF;
  border-radius: 1.5rem;
  resize: vertical;  /* Allow vertical resizing */
  font-family: inherit;
  min-height: 2.8rem;  /* Approximately one line + padding */
  max-height: 50vh;    /* Don't let it take up more than half the viewport */
  overflow-y: auto;    /* Show scrollbar when needed */
}

/* Style the resize handle */
.input-container textarea::-webkit-resizer {
  border-width: 8px;
  border-style: solid;
  border-color: transparent #E9ECEF #E9ECEF transparent;
}

.input-container button {
  padding: 0.8rem 1.5rem;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
}

.input-container button:hover {
  background: #0056b3;
}

.ilu2-toolbar {
  background: white;
  border-bottom: 1px solid #E9ECEF;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1002;
}

.toolbar-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.persistence-toggle {
  /* Add appropriate styles for the persistence toggle */
}

.tooltip {
  position: absolute;
  right: 0;
  top: 100%;
  width: 250px;
  padding: 8px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  margin-top: 8px;
  text-align: left;
}

.info-icon {
  position: relative;
  cursor: help;
  margin-left: 12px;
}

.toolbar-button {
  padding: 0.8rem 1.5rem;
  color: white;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
}

.toolbar-button.danger {
  background: #dc3545;
}

.toolbar-button.danger:hover {
  background: #bb2d3b;
} 